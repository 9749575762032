<template>
  <div>
    <div v-show="isloading == true" class="loadclass">
      <van-loading type="spinner">加载中...</van-loading>
    </div>
    <van-empty
      v-show="ishavemsg == false"
      :image="imges"
      description="- 空空如也，一条数据也没有 -"
    />

    <div v-show="withoutmsg == true" @click="clickload" class="allmore">
      <div v-show="open != true">
        <span class="iconclass">
          <van-icon name="arrow-down" />
        </span>
        <span>加载更多</span>
      </div>
      <div v-show="open == true">
        <van-loading type="spinner" color="#1989fa" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isloading: false,
      ishavemsg: true,
      thissettime: "",
      withoutmsg: false,
      open: false,
      url: "",
      imges: require("../../assets/nomsg.png"),
      arraymsg: [], //存储数据
    };
  },
  mounted() {
    this.$emit("loaddom", this.url);
  },
  methods: {
    //清空数据
    empty() {
      this.arraymsg = [];
    },
    clickload() {
      this.open = true;
      this.$emit("loaddom", this.url);
    },
    /**
     * 请求
     */
    loadhttpes(url, objes,type) {
      this.url = url;
      return new Promise((resolve, reject) => {
        this.thissettime = setTimeout(() => {
          this.isloading = true;
        }, 600);
        this.ishavemsg = true;
        this.$http
          .post(url, objes)
          .then((res) => {
            this.open = false;
            clearTimeout(this.thissettime);
            var obj = JSON.parse(res.data);
            
            if(obj.data.list){
              console.log(obj.data.list)
              Object.keys(obj.data.list).forEach((keys) => {
                if ( Object.prototype.toString.call(obj.data.list[keys]) =="[object Array]") {
                  if (obj.data.list[keys].length < 1) {
                    this.isloading = false;
                    this.ishavemsg = false;
                    this.withoutmsg = false;
                    resolve({
                      arraymsg: this.arraymsg,
                      res: JSON.parse(res.data),
                    });
                  } else {
                    for (let i = 0; i < obj.data.list[keys].length; i++) {
                      this.arraymsg.push(obj.data.list[keys][i]);
                    }
                    this.isloading = false;
                    this.ishavemsg = true;
                    this.withoutmsg = true;
                    Object.keys(objes).forEach((key) => {
                      if (key.indexOf("page") != -1) {
                        if (
                          objes[key] > obj.data.list.last_page ||
                          objes[key] == obj.data.list.last_page
                        ) {
                          this.withoutmsg = false;
                        }
                      }
                    });
                    
                    resolve({
                      arraymsg: this.arraymsg,
                      res: JSON.parse(res.data),
                    });
                  }
                } else {
                  this.isloading = false;
                }
              });
            }
            if(obj.data.data.length > 0){
              if(type == 0){this.arraymsg = []}
               Object.keys(obj.data).forEach((keys) => {
                if ( Object.prototype.toString.call(obj.data[keys]) =="[object Array]") {
                  if (obj.data[keys].length < 1) {
                    this.isloading = false;
                    this.ishavemsg = false;
                    this.withoutmsg = false;
                    resolve({
                      arraymsg: this.arraymsg,
                      res: JSON.parse(res.data),
                    });
                  } else {
                    for (let i = 0; i < obj.data[keys].length; i++) {
                      this.arraymsg.push(obj.data[keys][i]);
                    }
                    this.isloading = false;
                    this.ishavemsg = true;
                    this.withoutmsg = true;
                    Object.keys(objes).forEach((key) => {
                      if (key.indexOf("page") != -1) {
                        if ( objes[key] > obj.data.last_page ||  objes[key] == obj.data.last_page) {
                          console.log(235)
                          this.withoutmsg = false;
                        } 
                      }
                    });
                    
                    resolve({
                      arraymsg: this.arraymsg,
                      res: JSON.parse(res.data),
                    });
                  }
                } else {
                  console.log(333)
                  this.isloading = false;
                }
              });
            } else {
              this.ishavemsg = true
                resolve({
                  arraymsg: [],
                  res: JSON.parse(res.data),
                });
            }
            


          })
          .catch(() => {
            reject();
          });
      });
    },
  },
};
</script>
<style scoped>
.van-empty >>> .van-empty__image {
  width: 3rem;
  height: 2.7rem;
}
.loadclass {
  text-align: center;
  padding-top: 2rem;
}
.allmore {
  width: 80%;
  background-color: #ffffff;
  margin: 0.3rem 0 0.1rem 50%;
  transform: translate(-50%);
  text-align: center;
  position: relative;
  padding: 0.3rem 0.2rem;
  margin-bottom: 1rem;
  font-size: 0.35rem;
  border-radius: 2rem;
  color: #202124;
}
.iconclass {
  position: absolute;
  left: 0.45rem;
  top: 50%;
  transform: translateY(-50%);
}
</style>