<template>
  <div>
    <navigation msg="岗位管理"></navigation>
    <van-tabs v-model="typeVal" swipe-threshold="3" @click="change">
      <van-tab title="全部岗位"></van-tab>
      <van-tab title="已发布"></van-tab>
      <van-tab title="待发布"></van-tab>
      <van-tab title="审核驳回"></van-tab>
      <van-tab title="已关闭"></van-tab>
    </van-tabs>
    <div v-if="jobList.length > 0" class="postList">
      <div
        class="postItem"
        v-for="(item, index) in jobList"
        :key="index"
        @click="godetail(item.id, item.status, item.post_audit, item)"
      >
        <div class="flex">
          <div class="flexb">
            <span class="span1">{{ item.job_nickname }}</span>
            <!-- <span class="span2" v-if="item.status != 3">招聘中 </span> -->
            <span class="span2" style="margin-left: 0.1rem"
              >{{ getStatus1(item.post_audit) }}
            </span>
            <span
              :class="typeVal == 2 ? 'grey2' : 'span3'"
              style="margin-left: 0.1rem"
              >{{ getStatus(item.status) }}</span
            >
            <!-- <span class="span4">已跟进</span> -->
          </div>
          <div :class="typeVal == 2 ? 'grey' : 'salary'">
            {{ item.salary_min + "-" + item.salary_max }}
          </div>
        </div>
        <div class="grey">
          {{ getYearsWork(item.years_working) }} |
          {{ item.education_background }} | 招聘人数{{ item.recruit_number }}人
        </div>
        <div class="flex grey1">
          <div>
            <img
              style="
                width: 0.25rem;
                height: 0.25rem;
                vertical-align: bottom;
                margin-right: 0.1rem;
              "
              src="../../assets/time.png"
              alt=""
            />
            <span>{{ item.add_time }}</span>
          </div>
          <div>有效期 {{ effectivityTime(item.expire) }}</div>
        </div>
        <div class="grey1 flexb" style="margin-top: 0.25rem">
          <div class="abils">{{ item.job }}</div>
          <div>查看 ></div>
          <div v-if="status == 1" class="btn">去发布</div>
        </div>
        <div  class="count"  v-if="(typeVal == 0 || typeVal == 2) && item.is_view_count > 0"></div>
      </div>
    </div>
    <van-popup v-model="show" round>
      <div class="box">
        <div class="box1">岗位服务费</div>
        <div class="box2">
          <span>{{ post_price }}</span
          >元
        </div>
        <div class="box3"><span>账户余额：</span>{{ overall_balance }}</div>
        <div class="box4" @click="recharge">账户充值</div>
        <div class="box5">
          <div class="btn1" @click="show = false">等一下再来</div>
          <div class="btn2" @click="toPay">发布岗位</div>
        </div>
      </div>
    </van-popup>
    <loading @loaddom="loaddom" ref="loades"></loading>
    <div style="height: 1.3rem"></div>
  </div>
</template>
<script>
import loading from "../../components/loading/index";
export default {
  data() {
    return {
      show: false,
      pageno: 0,
      jobList: [],
      typeVal: 0,
      status: 0,
      post_id: "",
      post_price: "",
      overall_balance: "",
      post_audit:"",
    };
  },
  components: {
    loading,
  },
  mounted() {
    // this.getList(-1)
    // this.loaddom(-1)
  },
  methods: {
    issue() {
      this.$store.commit("SET_POST", {});
      this.$store.commit("SET_POST_STATUS", "add");
      this.$router.push("/step1");
    },
    change(e) {
      if (e == 0) {
        // 全部岗位
        this.pageno = 0;
        this.post_audit = ""
        this.status = 0
        this.jobList = [];
        this.loaddom(0);
      } else if (e == 1) {
        // 已发布
        this.pageno = 0;
        this.jobList = [];
        this.status = 2
        this.post_audit = ""
        this.loaddom(0);
      } else if (e == 2) {
        // 待发布
        this.pageno = 0;
        this.status = 1
        this.post_audit = ""
        this.jobList = [];
        this.loaddom(0);
      } else if (e == 3) {
        // 审核驳回
        this.pageno = 0;
        this.jobList = [];
        this.status = ""
        this.post_audit = 30
        this.loaddom(0);
      } else if(e == 4){
        // 已关闭
        this.pageno = 0;
        this.jobList = [];
        this.status = 3
        this.post_audit = ""
        this.loaddom(0);
      }
    },
    getStatus1(status) {
      let str;
      switch (status) {
        case 10:
          str = "未审核";
          break;
        case 20:
          str = "审核通过";
          break;
        case 30:
          str = "未通过";
          break;
      }
      return str;
    },
    // 岗位状态
    getStatus(status) {
      let str;
      switch (status) {
        case 0:
          str = "待发布";
          break;
        case 1:
          str = "待支付";
          break;
        case 2:
          str = "招聘中";
          break;
        case 3:
          str = "关闭";
          break;
      }
      return str;
    },
    recharge() {
      this.$router.push("/recharge");
    },
    getYearsWork(num) {
      let year;
      switch (Number(num)) {
        case 0:
          year = "不限";
          break;
        case 1:
          year = "1年以内";
          break;
        case 2:
          year = "1-3年";
          break;
        case 3:
          year = "3-5年";
          break;
        case 4:
          year = "5-10年";
          break;
        case 5:
          year = "10年以上";
          break;
      }
      return year;
    },
    // 有效期
    effectivityTime(type) {
      let str;
      switch (type) {
        case 1:
          str = "30天";
          break;
        case 2:
          str = "60天";
          break;
      }
      return str;
    },
    async toPay() {
      if (this.post_audit == 10 || this.post_audit == 30) {
        this.$message({
          message: "该岗位还未通过审核",
          type: "warning",
        });
        return;
      }

      let param = {
        reqType: "record",
        post_id: this.post_id,
        e_id: localStorage.getItem("conpanyId"),
        m_id: localStorage.getItem("userId"),
      };
      await this.$http.post("/firm/v1/record/jobPay", param).then((res) => {
        res = JSON.parse(res.data);
        if (res.code == 0) {
          this.show = false;
          this.$toast("支付成功！");
          this.pageno = 0;
          this.jobList = [];
          this.status = 1;
          this.loaddom(0);
        }
        if (res.code == 1) {
          this.$dialog
            .confirm({
              title: "提示",
              message:
                "您的余额不足，请完成充值后前往招聘悬赏待发布岗位中支付悬赏金额",
              confirmButtonText: "去充值",
              cancelButtonText: "取消",
            })
            .then(() => {
              this.$router.push("/recharge");
            })
            .catch(() => {
              // on cancel
            });
        }
      });
    },
    godetail(id, status, post_audit, item) {
      let that = this;
      this.$router.push({ path: "/personDetail/postAllDetail", query: { id } });
    },
    loaddom(type) {
      this.$refs.loades
        .loadhttpes(
          "/firm/v1/record/joblist",
          {
            reqType: "record", //请求类型
            m_id: localStorage.getItem("userId"), //用户id
            company_id: localStorage.getItem("conpanyId"), //公司id
            status: this.status,
            post_audit:this.post_audit,
            page: ++this.pageno, //页数
          },
          type
        )
        .then((res) => {
          console.log(res);
          this.jobList = res.arraymsg;
        });
    },
  },
};
</script>
<style scoped lang='scss'>
.box {
  position: relative;
  width: 5rem;
  height: 4.5rem;
  .box1 {
    text-align: center;
    margin-top: 0.8rem;
    font-size: 0.24rem;
  }
  .box2 {
    margin-top: 0.2rem;
    text-align: center;
    span {
      font-size: 0.55rem;
      color: rgb(235, 87, 54);
    }
  }
  .box3 {
    font-size: 0.24rem;
    margin-top: 0.2rem;
    text-align: center;
    span {
      font-size: 0.24rem;

      color: #9f9fa1;
    }
  }
  .box4 {
    color: #c2c2c4;
    text-align: center;
    border: 0.02rem solid #ccc;
    width: 1.8rem;
    border-radius: 0.05rem;
    margin: 0.3rem auto;
    padding: 0.2rem;
  }
  .box5 {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;

    display: flex;
    .btn1 {
      color: #6d6a6d;
      box-sizing: border-box;
      border-top: 0.02rem solid #ccc;
      height: 1rem;
      text-align: center;
      line-height: 1rem;
      flex: 1;
      font-size: 0.3rem;
      background-color: #fffeff;
    }
    .btn2 {
      color: #fff;
      height: 1rem;
      line-height: 1rem;
      font-size: 0.3rem;

      background-color: #e74c36;
      text-align: center;

      flex: 1;
    }
  }
}
.grey {
  color: #a6a8ad;
}
.grey2 {
  color: #a6a8ad;
  border: 0.01rem solid #a6a8ad;
  padding: 0.1rem 0.15rem;
  border-radius: 0.1rem;
  margin-right: 0.2rem;
}
.count {
  position: absolute;
  right: -0.05rem;
  top: -0.1rem;
  width: 0.15rem;
  height: 0.15rem;
  text-align: center;
  line-height: 0.3rem;
  background: #ff4822;
  color: #fff;
  border-radius: 50%;
}
.abils {
  padding: 0.1rem 0.3rem;
  background: #eee;
  border-top-left-radius: 0.35rem;
  border-bottom-right-radius: 0.35rem;
}
.btn {
  height: 0.5rem;
  width: 1.6rem;
  background-color: #ff4822;
  border-radius: 0.1rem;
  text-align: center;
  line-height: 0.5rem;
  color: #fff;
}
</style>